import React, { useState } from 'react';
import Form from '@routes/Home/Form';
import  Banner from '@routes/Home/Banner';
import AssetPreview from '@routes/Home/AssetPreview';
import { bpProps } from '@teespring/ts-components';
import { useSelector } from 'react-redux';
import './Home.scss';

function Home () {
  const [file, setFile] = useState(null);
  const [logo, setLogo] = useState(null);
  const [activeTemplate, setActiveTemplate] = useState('no-template');

  const { bpIsGT } = useSelector(state => ({ ...bpProps(state) }));

  return (      
    <div className="home container">
      <Banner />
      <section className="content splitview">
        <main className="main">
          <Form
            setFile={ setFile }
            file={ file }
            setLogo={ setLogo }
            logo={ logo }
            setActiveTemplate={ setActiveTemplate }
            activeTemplate={ activeTemplate }
          />
        </main>

        { bpIsGT('tabletMd') && (
          <aside className="aside">
             <AssetPreview
              id="artwork-preview" file={ file }
              activeTemplate={activeTemplate}
            />
          </aside>
        ) }
      </section>
    </div>
  );
};

export default Home;
