import { useState } from 'react';
import './TemplateSelector.scss';
import { RadioGroup, Tabs, ColorPicker, Toggle } from '@teespring/ts-components';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateStyle } from '@redux/actions';


function TemplateSelector(props) {
    const {
      activeTemplate,
      setActiveTemplate
    } = props;

    const dispatch = useDispatch();

    const [ activeBackgroundColor, setActiveBackgroundColor] = useState("#ffffff");
    const [ activecCardColor, setActivecCardColor] = useState("#ffffff");
    const { backgroundColor, cardBackgroundColor } = useSelector(state => state.templates);

    function onBackgroundColorChange(color){
      setActiveBackgroundColor(color.hex);
      let root = document.documentElement;
        root.style.setProperty('--customBackground', color.hex);
    }

    function onCardColorChange(color){
      setActivecCardColor(color.hex);
      let root = document.documentElement;
        root.style.setProperty('--customCardBackground', color.hex);
    }

    function onBackgroundSizeChange(event){
      let root = document.documentElement;
        root.style.setProperty('--cardSize', event.target.value+'%' );
    }

    const backgroundColors = [
        {
            id: 'bg-purple',
            className: 'purple'
        },
        {
            id: 'bg-orange',
            className: 'orange'
        },
        {
            id: 'bg-green',
            className: 'green'
        },
        {
            id: 'bg-blue',
            className: 'blue'
        },
        {
          id: 'bg-grey',
          className: 'grey'
        },
        {
          id: 'bg-black',
          className: 'black'
        },
        {
          id: 'custom',
          className: 'color-picker'
        }
    ]
    const cardBackgroundColors = [
      {
          id: 'white',
          className: 'white'
      },
      {
        id: 'custom',
        className: 'color-picker'
      }
    ]

    const tabItems = [
      { id: 'no-template', label: 'No template' },
      { id: 'card', label: 'Card' },
    ];
    
    return (
        <>
        <Tabs
          className="mb2"
          activeTab={ activeTemplate }
          items={ tabItems }
          onChange={ (tabId) => setActiveTemplate(tabId) }
        />
        { activeTemplate === 'card' &&
        <>
        <h2>Image</h2>
        <Toggle
          elementType="checkbox"
          id="checkbox-option-1"
          name="checkbox-options"
          checked={ false }
          onChange={ (event) => dispatch(setTemplateStyle({ constrainImage: event })) }
          title="Resize card to fit image"
        />
        <input 
          type="range"
          id="card-size"
          name="points"
          min="0"
          max="300"
          onChange={ onBackgroundSizeChange }
        />
        <div className='divider'></div>
        <h2>Card color</h2>
        <RadioGroup
          defaultCheckedId={ cardBackgroundColor }
          onChange={ (event) => dispatch(setTemplateStyle({ cardBackgroundColor: event })) }
          className="template-bg-color"
          items={ cardBackgroundColors.map(color => {
            return ({ ...color, checked: color?.id === cardBackgroundColor })
          }) }
        />
        { cardBackgroundColor === 'custom' && (
        <ColorPicker
          defaultOpen={ false }
          initialColor={activecCardColor}
          onChange={ onCardColorChange }
          onClose={ () => console.log('close event') }
        />
        )}
        <div className='divider'></div>
        <h2>Background</h2>
        <RadioGroup
          defaultCheckedId={ backgroundColor }
          onChange={ (event) => dispatch(setTemplateStyle({ backgroundColor: event })) }
          className="template-bg-color"
          items={ backgroundColors.map(color => {
            return ({ ...color, checked: color?.id === backgroundColor })
          }) }
        />
        { backgroundColor === 'custom' && (
        <ColorPicker
          defaultOpen={ false }
          initialColor={activeBackgroundColor}
          onChange={ onBackgroundColorChange }
          onClose={ () => console.log('close event') }
        />
        )}
        </>

        }
     </>  
    );
  };

export default TemplateSelector;