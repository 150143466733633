import { Icon } from "@teespring/ts-components";
import './Header.scss';

function Header () {
  return (
    <header className="header">
      <div className="container">
        <div>
          <a href="https://teespring.com/design-launcher" target="_top" rel="noreferrer">
            <Icon className="header__back" name="ArrowLeft" />
          </a>
          <span className="header__title">Sell NFTs</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
