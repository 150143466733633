import { cloneElement, useState } from 'react';
import { motion } from "framer-motion"
import propTypes from 'prop-types';
import './Input.scss';
function Input (props) {
  const {
    elemType,
    className,
    label,
    name,
    hint,
    type,
    register,
    prefix,
    errors,
    validators,
    autoComplete,
    step,
    min,
    max
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  let validation = {};
  if (validators) validation = { validate: validators }

  const registerProps = register(name, validation);

  const onBlurHandler = (event) => {
    setIsFocused(false);
    registerProps?.onBlur(event);
  };

  const onChangeHandler = (event) => {
    setValue(event?.target?.value);
    registerProps?.onChange(event);
  };

  const elem = elemType === 'textarea' ? <textarea /> : <input />
  const elemProps = {
    className: `input ${className}`,
    onFocus: () => setIsFocused(true),
    onBlur: event => onBlurHandler(event),
    onChange: event => onChangeHandler(event),
    type,
    value,
    name,
    autoComplete,
    step,
    min,
    max,
    ref: registerProps?.ref
  };

  const animationVariants = {
    focus: {
      y: '-45%',
      fontSize: '12px',
      color: '#757575'
    },
    unfocus: {
      y: '50%',
      fontSize: '16px',
      color: '#000000'
    }
  };

  const innerInput = (
      <div className={ `input__wrapper ${errors[name] ? 'has-errors' : ''}` }>
        <motion.label
          className="input__label"
          htmlFor={ name }
          variants={ animationVariants }
          animate={ (isFocused || value.length > 0) ? 'focus' : 'unfocus' }
        >
          { label }
        </motion.label>
        { cloneElement(elem, { ...elemProps }) }

        {prefix ? "" : (
          <>
          {hint && <p className="input__hint">{ hint }</p>}
          {errors[name] && <p className="form__error">{ errors[name]?.message }</p>}
          </>
        )}
      </div>
  )

  return (
    <>
      { prefix ? (
      <>
      <div className="input__prefix-wrapper">
        <div className='input__prefix'>
          <span>{ prefix }</span>
        </div>
        { innerInput }
      </div>
      {hint && <p className="input__hint">{ hint }</p>}
      { errors[name] && <p className="form__error">{ errors[name]?.message }</p> }
      </>
      ) : (
        <>
        { innerInput }
        </>
      )}
    </>
  )
};

const { string } = propTypes;
Input.propTypes = {
  className: string.isRequired
};

Input.defaultProps = {
  className: '',
  type: 'text',
  autoComplete: '',
  step: '',
  min: '',
  max: ''
};

export default Input;
