import { useState } from 'react';
import UploadInput from '@components/UploadInput';
import { useNavigate } from 'react-router-dom';
import Input from '@components/Input';
import CheckboxInput from '@components/CheckboxInput';
import AssetPreview from '@routes/Home/AssetPreview';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { validatorsHF, LoaderButton, bpProps } from '@teespring/ts-components';
import { formatBytes } from '@utils/generalUtils';
import './Form.scss';
import Notice from '../../../components/Notice';
import TemplateSelector from '@routes/Home/TemplateSelector';
import { toBlob } from 'html-to-image';

function encode(data) {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}

const maxNftFileSize = 15728640; // 15mb
const maxLogoFileSize = 2097152; // 2mb
const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
const humanReadableImageTypes = acceptedImageTypes.map(type => type.split('image/')[1]).join(', ');

function Form (props) {
  const { setFile, file, setLogo, activeTemplate, setActiveTemplate } = props;
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      file: null,
      logo: null,
      blob: null,
      email: '',
      fullname: '',
      'about-you': '',
      title: '',
      description: '',
      price: '',
      'social-link': '',
      'spring-contract': false,
      'bondly-ula': false
    }
  });

  const navigate = useNavigate();
  const [globalError, setGlobalError] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const { bpIsLT } = useSelector(state => ({ ...bpProps(state) }));

  const onSubmit = async (data, event) => {
    console.log("Template on submission is:" +activeTemplate);
    setUploadingFile(true);
    const HOST = process.env.REACT_APP_APIHOST;
    const KEY = process.env.REACT_APP_APIKEY;
    const fileNames =  Object.keys(data).filter(key => data[key] instanceof File);
    const files = {};

    // Stack promises to call for each file input
    const promises = fileNames.map(async name => {
      // const file = data[name];
      let file = data[name];
      if (activeTemplate === 'card' && name === 'file') {
        await toBlob(document.getElementById('artwork-preview'))
        .then(function (blob) {
          file = new File([blob], "my_image.png",{type:"image/png", lastModified:new Date().getTime()})
        });
      }

      if (file instanceof File) {
        const res = await fetch(`${HOST}/v1/assets/upload?mimeType=${file?.type}`,{
          method: "GET",
          headers: { 'x-api-key': KEY },
        });

        const body = await res.json();

        const renamedFile = new File([file], body?.filename, { type: file.type });
        await fetch(body?.signedUrl, {
          method: 'PUT',
          body: renamedFile,
          headers: { 'Content-Type': file?.type }
        });

        files[name] = body?.signedUrl.split('?')[0];
      }
    });

    await Promise.all(promises).then(() => {
      if (Object.keys(files).length === fileNames.length) {        
        fetch('/', {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "mod-nft-generation",
            ...data,
            ...files
          }),
        })
          .then((response) => {
            console.log(response);
            setUploadingFile(false);
            navigate('/thankyou');
          })
          .catch((error) => {
            console.log(error);
            setGlobalError('There was an error submitting your form. Please try again.');
          })
      }  
    });
    
    event.preventDefault();
  }

  const throwFileError = (name, message) => {
    setError(name, {
      type: 'manual',
      message
    });
  };

  const {
    isValidEmail,
    isFullName,
    isValidUrl,
    required,
    isTrue
  } = validatorsHF;

  function setCardImage(file) {
    const cardImage = new File([file], "cardimage.png",{type:"image/png", lastModified:new Date().getTime()})
    let root = document.documentElement;
    root.style.setProperty('--cardImage', 'url('+URL.createObjectURL(cardImage)+')' );
  }

  return (
    <form
      name="mod-nft-generation"
      className="form"
      onSubmit={ handleSubmit(onSubmit) }
      method="post"
    >
      <input type="hidden" name="form-name" value="mod-nft-generation" />
      <input
        type="hidden"
        name="formId"
        value="mod-nft-generation"
        { ...register('formId') }
      />
      
      <label
        htmlFor="got-ya"
        style={ {
          position: "absolute",
          overflow: "hidden",
          clip: "rect(0 0 0 0)",
          height: "1px",
          width: "1px",
          margin: "-1px",
          padding: "0",
          border: "0",
        } }
      >
        Don’t fill this out if you're human:
        <input tabIndex="-1" name="got-ya" { ...register('got-ya') } />
      </label>

      <div className="content-box">
        <h1>Create your NFT</h1>
        <p>Note that we currently only accept image files in the beta phase. <br></br>More info on other file formats coming soon.</p>

        <h2>Asset</h2>

        { bpIsLT('tabletMd') && (
          <AssetPreview
            activeTemplate={ activeTemplate }
            className="nftpreview"
            file={ file }
          /> 
        ) }

        <UploadInput
          name="file"
          register={ register }
          onChange={ file => {
            setValue('file', file);
            setFile(file);
            setCardImage(file);
          } }
          onFileSizeInvalid={ () => throwFileError('file', `The file you uploaded is too large. Please upload a file less than or equal to ${formatBytes(maxNftFileSize)}`) }
          onFileTypeInvalid={ () => throwFileError('file', `The file you uploaded is the wrong file type. Please make sure your file is one of the following: [${humanReadableImageTypes}]`) }
          clearError={ () => clearErrors('file') }
          validators={ { required } }
          maxFileSize={ maxNftFileSize }
          acceptedImageTypes={ acceptedImageTypes.join(', ') }
        />

        { errors?.file && <p className="form__error">{ errors?.file?.message }</p> }

        <div className="content-subbox">
          Recommended size: 1200 x 1200px< br />
          Max file size { formatBytes(maxNftFileSize) }
        </div>
        
        <h2>Templates</h2>
        <TemplateSelector
          activeTemplate={activeTemplate}
          setActiveTemplate={setActiveTemplate}
          maxNftFileSize={ maxNftFileSize }
        />
        <input type="hidden" name="templateid" value={activeTemplate} { ...register('templateid') }/>

      </div>


      <div className="content-box">
        <h1>About you</h1>

        <Input
          name="email"
          type="email"
          label="Spring account email"
          hint="Email used to log in to your Spring account."
          register={ register }
          errors={ errors }
          validators={ { isValidEmail } }
          autoComplete="email"
        />

        <Input
          name="fullname"
          type="text"
          label="Full name"
          register={ register }
          errors={ errors }
          validators={ { isFullName } }
          autoComplete="name"
        />

        <Input
          name="social-link"
          type="url"
          label="Your primary social platform link"
          register={ register }
          errors={ errors }
          hint="i.e. YouTube channel, Instagram profile, TikTok profile"
          validators={ { isValidUrl } }
          autoComplete="url"
        />
        
        <Input
          elemType="textarea"
          type="text"
          name="about-you"
          label="About you"
          hint="Please provide 2 sentences describing you and your work."
          register={ register }
          errors={ errors }
          validators={ { required } }
        />

        <h2>Your logo or profile picture</h2>
        <p>Please upload a profile picture or logo (.jpeg or .png) that will be used on your profile across all NFT marketplaces.</p>
        
        <UploadInput
          name="logo"
          register={ register }
          onChange={ logo => {
            setValue('logo', logo);
            setLogo(logo);
          } }
          onFileSizeInvalid={ () => throwFileError('logo', `The file you uploaded is too large. Please upload a file less than or equal to ${formatBytes(maxLogoFileSize)}`) }
          onFileTypeInvalid={ () => throwFileError('logo', `The file you uploaded is the wrong file type. Please make sure your file is one of the following: [${humanReadableImageTypes}]`) }
          clearError={ () => clearErrors('logo') }
          validators={ { required } }
          errorMessage="You must upload a file to use as an avatar."
          maxFileSize={ maxLogoFileSize }
          acceptedImageTypes={ acceptedImageTypes.join(', ') }
        />

        { errors?.logo && <p className="form__error">{ errors?.logo?.message }</p> }

        <div className="content-subbox">
          Recommended size: 400 x 400< br />
          Max file size {formatBytes(maxLogoFileSize)}
        </div>

      </div>

      <div className="content-box">
        <h1>NFT details</h1>

        <Input
          name="title"
          type="text"
          label="Title"
          hint="This name will be used as the listing title once it’s created.  "
          register={ register }
          errors={ errors }
          validators={ { required } }
        />

        <Input
          elemType="textarea"
          name="description"
          type="text"
          label="Description"
          hint="This will be used as the listing description once created."
          register={ register }
          errors={ errors }
          validators={ { required } }
        />

        <Input
          className="form__price"
          name="price"
          type="number"
          label="Price"
          prefix="US$"
          min="20"
          max="5000"
          step="any"
          register={ register }
          errors={ errors }
          validators={ { required } }
          hint="Please note the minimum selling price for NFTs is $20 and the maximum is $5,000. This enables Spring to provide the Mint-On-Demand™ service with no upfront fees."
        />
        <p>
          You will receive&nbsp;
          <strong>70% profit</strong>&nbsp;
          on the selling price and a&nbsp;
          <strong>7% royalty on all secondary market sales.</strong>
        </p>
        <Notice 
          message={ (
            <span>
              We currently only support a quantity of <strong>one unit</strong> per listing. If you want to make more available using the same asset, you will need to launch them as separate listings.
            </span>
          ) }
        />
          
      </div>

      <div className="content-box">
        <h1>Agreements</h1>
        <h2>Spring contract</h2>
        <CheckboxInput
          name="spring-contract"
          label={ (
            <span>
              By checking this box and clicking “submit” I hereby agree to the Spring NFT&nbsp;
              <a
                className="typ--link"
                href="https://www.spri.ng/policies/spring-terms-of-service?section=mint-on-demand-terms-of-service"
                target="_blank"
                rel="noreferrer"
              >terms of service</a>
            </span>
          ) }
          register={ register }
          errors={ errors }
          validators={ { isTrue } }
        />

        <h2>Bondly ULA</h2>
        <CheckboxInput
          name="bondly-ula"
          label={ (
            <span>
              By checking this box and clicking “submit” I hereby agree to the&nbsp;
              <a
                className="typ--link"
                href="https://bondly.gitbook.io/bondlyula/"
                target="_blank"
                rel="noreferrer"
              >Bondly ULA</a>
            </span>
          ) }
          register={ register }
          errors={ errors }
          validators={ { isTrue } }
        />
      </div>

      { globalError && <p className="form__error">{ globalError }</p> }

      <LoaderButton
        className="btn"
        type="submit"
        text="Submit &amp; agree"
        loading={ uploadingFile }
        loadingIconOnly={ true }
      />
    </form>
  );
};

export default Form;
