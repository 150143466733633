import { useSelector } from 'react-redux';
import './AssetPreview.scss';

function AssetPreview (props) {
  const { backgroundColor, cardBackgroundColor, constrainImage } = useSelector(state => state.templates);
  const { file, className, activeTemplate, id } = props;

  const template1 = (
    <>
    { file && <img src={ URL.createObjectURL(file) } alt="Your NFT" /> }
    </>
  )

  const template2 = (
    <>
    
    <div 
      className={ `card ${cardBackgroundColor === 'custom' ? 'custom-color' : ''} ${constrainImage === true ? '' : 'constrain-image'}` }
      style={{
      }}
    > 
      {constrainImage === true && (
        file && <img src={ URL.createObjectURL(file) } alt="Your NFT" />
      )}
      
    </div>
    </>
  )

  return (
    <div className={ `assetpreview__wrapper  ${file ? 'has-file' : ''}` }>
      <div className={ `assetpreview ${activeTemplate } ${className}` }>
        <figure id={id} style={{
          width: "100%",
          aspectRatio: 1,
        }}
          className={ `assetpreview__frame ${activeTemplate === 'card' ? backgroundColor : '' }` }>
          { activeTemplate === 'no-template' && template1 }
          { activeTemplate === 'card' && template2 }
        </figure>
      </div>
      <span className="assetpreview__label mb1">NFT PREVIEW</span>
    </div>
  )
}

AssetPreview.defaultProps = {
  className: '',
  id:''
};

export default AssetPreview;
