const initialState = { backgroundColor: 'bg-purple', cardBackgroundColor: 'white', constrainImage: false, backgroundSize: 'cover' };

const templates = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TEMPLATE_STYLE': {
      return {
        ...state,
        ...action.styles
    };
    }
    default:
      return state;
  }
};

export default templates;
