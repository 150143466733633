import './Notice.scss';

function Notice ({message}) {
    return (

        <div className="notice">
            {message}
        </div>
    );
  };
  
  export default Notice;
  