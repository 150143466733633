import { useState } from 'react';
import { Icon } from '@teespring/ts-components';
import { formatBytes } from '@utils/generalUtils';
import './UploadInput.scss';
function UploadInput (props) {
  const {
    onFileSizeInvalid,
    onFileTypeInvalid,
    onChange,
    register,
    name,
    clearError,
    maxFileSize,
    acceptedImageTypes,
    errorMessage
  } = props;

  const [localFile, setLocalFile] = useState(null);

  const registerProps = register(name, { validate: value => {
    return value instanceof File
    || errorMessage
  } });

  const processSelectedFile = async (files) => {
    const file = files[0];

    if (!file) return;

    if (!acceptedImageTypes.match(file.type)) {
      return onFileTypeInvalid();
    } else if (file.size >= maxFileSize) {
      return onFileSizeInvalid();
    } else {
      clearError();
      onChange(file);
      setLocalFile(file);
    }
  };

  const elemProps = {
    ...registerProps,
    onChange: event => {
      registerProps?.onChange(event);
      processSelectedFile(event?.target?.files);
    }
  };

  const onDelete = () => {
    clearError();
    onChange(null);
    setLocalFile(null);
  };

  const preview = (fil) => (
    <div className="uploadinput__preview">
      <img src={ URL.createObjectURL(fil) } alt={ `${fil?.name} thumbnail` } />
      <div>
        <strong>{ fil?.name }</strong>
        <span>{ formatBytes(fil?.size) }</span>
      </div>

      <button type="button" onClick={ onDelete }>
        <Icon className="uploadinput__delete" name="XSquare" />
      </button>
    </div>
  );

  const input = (
    <div className="uploadinput">
      <input
        { ...elemProps }
        type='file'
        accept={ acceptedImageTypes }
        className='uploadinput__field'
      />
      <p>Drop files here to upload...</p>
      <span className="uploadinput__button">
        Browse files
      </span>
    </div>
  );

  return localFile
    ? preview(localFile)
    : input;
};

UploadInput.defaultProps = {
  maxFileSize: 2000000,
  acceptedImageTypes: 'image/jpeg, image/jpg, image/png',
  errorMessage: 'You must upload a file to use as an NFT.'
};

export default UploadInput;
