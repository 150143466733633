import BpConnect from '@components/BpConnect';
import Home from '@routes/Home';
import Thankyou from '@routes/Thankyou';
import Header from '@components/Header';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.scss';

// Adds 'is-touch-device' class to html as soon as the first touchstart
// event is fired. At this point,  we can be reasonably assured the user
// is using touch to interact with the site.
const initTouch = () => {
  const htmlClasses = document.querySelector('html').classList;
  if (!htmlClasses.contains('is-touch-device')) {
    htmlClasses.add('is-touch-device');
  }
};

window.addEventListener('touchstart', initTouch);

function App() {
  return (
    <BrowserRouter>
      <BpConnect />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="thankyou" element={<Thankyou />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
