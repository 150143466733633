import './Banner.scss';

function Banner () {
  return (
    <div className="banner content-box">
      <img
        className="banner__rocketimg"
        src={ `${process.env.PUBLIC_URL}/assets/rocket.png` }
        alt="Rocket ship"
      />
      <div className="banner__copy">
        <div className="banner__copyinner">
          <img
            src={ `${process.env.PUBLIC_URL}/assets/rocket.png` }
            alt="Rocket ship"
          />
          <h3>You’ve been accepted into the Mint-On-Demand™ beta program.</h3>
        </div>
        <p className="mb0">Submit your NFT listing request and we’ll email you to confirm we received it. We’ll aim to process all requests within 3 - 5 business days of submission. </p>
      </div>

      <a
        className="banner__btn"
        href="https://mint-on-demand.spri.ng/"
        target="_blank"
        rel="noreferrer"
      >
        <span>Learn more</span>
        <em className="banner__questionicon">?</em>
      </a>
    </div>
  );
};

export default Banner;
