/**
 * Changes the template style
 *
 * @param {Object} styles          Object containing key value pairs for template styles to update
 * @return {Object} Action object
 */
export const setTemplateStyle = ( styles ) => ({
  type: 'SET_TEMPLATE_STYLE',
  styles: { ...styles }
});
