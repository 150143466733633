import { useState } from 'react';
import './CheckboxInput.scss';

function CheckboxInput (props) {
  const {
    className,
    name,
    register,
    label,
    errors,
    validators
  } = props;

  const [value, setValue] = useState('');

  let validation = {};
  if (validators) validation = { validate: validators }

  const registerProps = register(name, validation);

  const onChangeHandler = (event) => {
    setValue(event?.target?.value);
    registerProps?.onChange(event);
  };

  const elemProps = {
    ...registerProps,
    className: `checkbox ${className}`,
    onChange: event => onChangeHandler(event),
    value,
    name,
    ref: registerProps?.ref
  };

  return (
    <>
      <label className="checkbox__wrapper">
        <input type="checkbox" { ...elemProps } />
        <span className="checkbox__label">{ label }</span>
      </label>
      { errors[name] && <p className="form__error">{ errors[name]?.message }</p> }
    </>
  );
};

export default CheckboxInput;
