import './Thankyou.scss';

function Thankyou () {
  return (
    <div className="thankyou row">
      <section className="content">
        <h1>Thanks for submitting your NFT request</h1>
        <p>We’ll review your request within the next 3 - 5 business days. Please expect an email update soon.</p><p>In the meantime, create another limited edition NFT now.</p>
        <div className='btn__row'>
          <a href="https://teespring.com/design-launcher/special-product-request/dc688caa-2513-4837-8741-8fc94fadf717?utm_source=platform&utm_medium=launcher_NFT_post_launch_page&utm_campaign=mint_on_demand&utm_content=NFT_post_launch_page_CTA" target="_top" className="btn">Create another NFT</a>
          <a href="https://teespring.com/design-launcher" target="_top" className="btn tertiary">Browse other products</a>
        </div>
      </section>
    </div>
  );
};

export default Thankyou;
